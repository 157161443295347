<template>
	<v-col cols="10" sm="6" class="pa-0 mt-10 pt-10 text-left mx-auto">
		<div class="font-bold text-2xl sm:text-4xl mt-10 pt-10 text-capitalize">{{ dataItems.title }}</div>
		<div class="text-sm py-4">{{bahasa? 'Terakhir diubah' : 'Last Update'}}: {{ dataItems.lastUpdate || '-' }}</div>
		<v-skeleton-loader
			v-if="isLoad"
			type="paragraph"
			class="mt-3"
		></v-skeleton-loader>
		<div v-else>
			<div class="mb-10 mt-5">
				<div v-if="dataItems" v-html="dataItems.desc" />
				<span v-else>-</span>
			</div>
		</div>
	</v-col>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';
export default {
	props: {
		bahasa: {
			type: Boolean,
			default: true
		},
		id: {
			type: String,
			require: false
		}
	},
	data() {
		return {
			isLoad: false,
			dataItems: [],
			baseUrl: process.env.VUE_APP_BASE_URL,
			lastUpdated: ''
		}
	},
	computed: {
		language() {
      return this.bahasa ? 'ID' : 'ENG';
    }
	},
	watch: {
		language() {
      this.getDetail();
    }
	},
	mounted() {
    this.getDetail();
	},
	methods: {
		async getDetail() {
			this.isLoad = true;
			let param = {
				lang: this.language
			}
      await axios.get(this.baseUrl + '/career' + '/' + this.id, {params: param}).then(response => {
        if (response.data.code === 200) {
          this.dataItems = response.data.data;
          this.lastUpdated = response.data.data.latUpdate ? moment(response.data.data.latUpdate).format('DD MMMM YYYY') : '-';
        }
      })
      .catch(error => {
				if (error.response.data.code === 404) {
					this.$router.push({name: 'NotFound'})
				} else {
					this.isLoad = false;
					Swal.fire('Failed!', error.response.data.message, 'error');
				}
      })
      .finally(() => this.isLoad = false);
		}
	}
}
</script>